import * as PIXI from 'pixi.js';
import { JigsawPiece } from './piece';
import { GameState } from '.';

type Params = {
    onPieceMoved: (index: number, x: number, y: number) => void,
    gameState: GameState
}

type Nest = { x: number; y: number; position: { x: number; y: number; }; }

export default class JigsawGame extends PIXI.Application {

    private imageScale = 1;
    private preview?: PIXI.Sprite;
    private nests: Nest[] = [];
    pieceW: number = 0;
    pieceH: number = 0;
    pieces: JigsawPiece[] = [];

    constructor(private params: Params) {
        super();

        this.loadTextures().then((resources) => {
            this.initGame()
        })
    }

    private loadTextures(): Promise<Partial<Record<string, PIXI.LoaderResource>>> {
        return new Promise((resolve) => {
            this.loader.add('image', this.params.gameState.image);

            this.loader.load((loader, resource) => {
                resolve(resource)
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })
        })
    }

    private initGame() {
        this.imageScale = Math.min(this.screen.height / this.loader.resources.image.texture.height * .8, this.screen.width / this.loader.resources.image.texture.width * .8, 1);
        this.pieceW = (this.loader.resources.image.texture.width / this.params.gameState.width) * this.imageScale;
        this.pieceH = (this.loader.resources.image.texture.height / this.params.gameState.height) * this.imageScale;
        this.preview = this.initPreview();
        this.createPieces();
        this.nests = this.createNests()
    }

    private initPreview() {
        const preview = new PIXI.Sprite(this.loader.resources.image.texture);

        preview.anchor.set(.5);
        preview.scale.set(this.imageScale);
        preview.position.y = this.screen.height / 2;
        preview.position.x = this.screen.width / 2;
        preview.alpha = .3;

        this.stage.addChild(preview);

        return preview;
    }

    private createPieces() {
        this.pieces = (new Array(this.params.gameState.width * this.params.gameState.height))
            .fill('')
            .map((_, index) => {
                const pw = this.loader.resources.image.texture.width / this.params.gameState.width;
                const ph = this.loader.resources.image.texture.height / this.params.gameState.height;

                const x = index % this.params.gameState.width;
                const y = Math.floor(index / this.params.gameState.height);

                return new PIXI.Texture(this.loader.resources.image.texture as any, new PIXI.Rectangle(x * pw, y * ph, pw, ph));
            })
            .map((texture, index) => {
                const sprite = new JigsawPiece(texture, index);

                sprite.anchor.set(.5);
                sprite.scale.set(this.imageScale);

                sprite
                    .on('pointerup', () => this.snapToNest(sprite, this.nests, this.pieceW, this.pieceH))
                    .on('pointerupoutside', () => this.snapToNest(sprite, this.nests, this.pieceW, this.pieceH))

                return sprite;
            })
            .map(sprite => {
                const gsp = this.params.gameState.pieces.find(({id}) => id === sprite.id)
                if (gsp) {
                    sprite.position.set(gsp.x, gsp.y)
                }

                return sprite
            });
        this.pieces
            .forEach(sprite => {
                this.stage.addChild(sprite);
            });
    }

    private snapToNest(piece: JigsawPiece, nests: Nest[], pieceW: number, pieceH: number) {
        const target = nests.reduce<Nest | null>((prev, next) => {
            if (Math.abs(piece.position.x - next.position.x) < pieceW/2 && Math.abs(piece.position.y - next.position.y) < pieceH/2) {
                return next;
            }

            return prev;
        }, null)

        if (target) {
            piece.position.x = target.position.x;
            piece.position.y = target.position.y;
        }
        this.params.onPieceMoved(piece.id, piece.position.x, piece.position.y);
    }

    private createNests() {
        return (new Array(this.params.gameState.width * this.params.gameState.height))
            .fill('')
            .map((_, index) => {
                const x = index % this.params.gameState.width;
                const y = Math.floor(index / this.params.gameState.height);

                const position = {
                    x: this.screen.width / 2 + ((x - this.params.gameState.width / 2) * this.pieceW) + this.pieceW / 2,
                    y: this.screen.height / 2 + ((y - this.params.gameState.height / 2) * this.pieceH) + this.pieceH / 2
                }

                return {
                    x,
                    y,
                    position
                }
            });
    }

    public movePiece(id: number, x: number, y: number) {
        const piece = this.pieces.find(p => p.id === id);

        if (piece) {
            if (piece.position.x !== x || piece.position.y !== y) {
                piece.position.set(x,y);
            }
        }
    }
}
