"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class BaseUserModel {
    constructor(_id, _permisions, _data) {
        this._id = _id;
        this._permisions = _permisions;
        this._data = _data;
    }
    static from(data) {
        return new BaseUserModel(data.id, data.permisions, data.data);
    }
    get id() {
        return this._id;
    }
    ;
    get permisions() {
        var _a;
        return [
            ...this._permisions,
            ...((_a = this._room) === null || _a === void 0 ? void 0 : _a.permisions) || []
        ];
    }
    get data() {
        return this._data;
    }
    serialize() {
        return {
            id: this.id,
            permisions: this.permisions,
            data: Object.assign({}, this._data || {})
        };
    }
    get room() {
        return this._room || null;
    }
    enterRoom(room) {
        this._room = room;
    }
    exitRoom() {
        var _a;
        (_a = this._room) === null || _a === void 0 ? void 0 : _a.removeUser(this);
        this._room = null;
    }
}
exports.default = BaseUserModel;
