import { useRooms } from "../../../../modules/game/hooks/rooms/use-rooms";

export default function MainLobbyRoom() {

    const {enter} = useRooms();

    return <div>
        <h2>Main Lobby Room</h2>

        <button onClick={() => {enter('room-1')}}>game-room-1</button>
    </div>
}
