import { useCallback } from "react";
import { useGameClient } from "../..";

type Params = {
    game: string,
    id: number,
    x: number,
    y: number
}

export default function useSendPieceMoveGameMessage() {
    const {engine} = useGameClient();

    return useCallback(({game, id, x, y }: Params) => {
        engine.pieceMoved(game, id, x, y);
    }, [engine]);
}
