import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { GameClientProvider } from './modules/game';
import useConfig from './modules/main/hooks/use-config';
import useIsLogged from './modules/main/hooks/use-is-logged';
import GamePage from './routes/game';

import HomePage from './routes/home';
import LoginPage from './routes/login';
import { getGameRoute, getLoginRoute } from './routes/routes';

export default function App() {
    const {gameAuthToken} = useConfig();

    return (
        <BrowserRouter>
            <Switch>
                <OnlyForLoggedRoute path={getGameRoute()}>
                    <GameClientProvider authToken={gameAuthToken || ''}>
                        <GamePage />
                    </GameClientProvider>
                </OnlyForLoggedRoute>
                <OnlyForUnloggedRoute path={getLoginRoute()}>
                    <LoginPage />
                </OnlyForUnloggedRoute>

                <Route component={HomePage} />
            </Switch>
        </BrowserRouter>
    )
}

const OnlyForLoggedRoute: React.FC<any> = ({ children, ...rest }: any) => {

    const { isLogged } = useIsLogged();

    return <Route {...rest}>{
        isLogged
            ? children
            : <Redirect to={getLoginRoute()} />
    } </Route>
}

const OnlyForUnloggedRoute = ({ children, ...rest }: any) => {

    const { isLogged } = useIsLogged();

    return <Route {...rest}>{
        isLogged
            ? <Redirect to={getGameRoute()} />
            : children
    } </Route>
}
