
import JigsawGame from "./game";
import { GameState, API } from ".";

type GameConfig = {
    container: HTMLElement;
    onPieceMoved: (index: number, x: number, y: number) => void,
    gameState: GameState
}

export default function createJigsaw(config: GameConfig): API {
    const app = new JigsawGame({
        gameState: config.gameState,
        onPieceMoved: config.onPieceMoved
    })

    config.container.appendChild(app.view);

    return {
        movePiece: (id: number, x: number, y: number) => {
            app.movePiece(id, x, y);
        }
    };
}
