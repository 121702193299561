import createClient from "./create-client";


// const WS_URL = "ws://localhost:8081";
const WS_URL = "wss://jigsaw-server.gde-mp.borek224.usermd.net";

const {
    GameClientProvider,
    useGameClient
} = createClient(WS_URL);


export {
    GameClientProvider,
    useGameClient
}
