"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JigsawEngineClient = void 0;
const gde_game_framework_1 = require("gde-game-framework");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
class JigsawEngineClient {
    constructor() {
        this.$messages = new rxjs_1.Subject();
        this.gameState = new rxjs_1.BehaviorSubject(null);
        this.$updates = new rxjs_1.Subject();
    }
    handleAction() {
    }
    update(data) {
        this.$updates.next(data);
    }
    getRoomState(id) {
        // const gameState = this.gameState.value;
        // if (gameState) {
        //     return Promise.resolve(
        //         gameState.rooms.find(r => r.id === id) || null
        //     );
        // }
        return new Promise((resolve) => {
            const msg = new gde_game_framework_1.Message(gde_game_framework_1.GAME_MESSAGE_TYPE.REQUEST, {
                dataType: 'game-state'
            }, {
                requireResponse: true
            });
            msg.$response
                .pipe(operators_1.first())
                .subscribe(msg => {
                const gameState = msg.data;
                this.gameState.next(gameState);
                resolve(gameState.rooms.find(r => r.id === id) || null);
            });
            this.$messages.next(msg);
        });
    }
    getPieceMoved(roomId) {
        return this.$updates
            .pipe(operators_1.filter(u => u.type === 'piece-moved'), operators_1.map(u => u.payload), operators_1.filter(u => {
            return u.roomId === roomId;
        }), operators_1.map(v => {
            return {
                id: v.pieceId,
                x: v.x,
                y: v.y
            };
        }));
    }
    pieceMoved(roomId, pieceId, x, y) {
        const msg = new gde_game_framework_1.Message(gde_game_framework_1.GAME_MESSAGE_TYPE.ACTION, {
            action: 'piece-moved',
            payload: {
                roomId, pieceId, x, y
            }
        });
        this.$messages.next(msg);
    }
}
exports.JigsawEngineClient = JigsawEngineClient;
const client = new JigsawEngineClient();
exports.default = client;
