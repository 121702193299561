"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const gde_game_framework_1 = require("gde-game-framework");
const rxjs_1 = require("rxjs");
const gameState = {
    rooms: []
};
function reset() {
    gameState.rooms = [
        {
            id: 'room-1',
            name: '4x4 Islandia',
            complete: false,
            image: '/P1016548.jpg',
            width: 4,
            height: 4,
            pieces: (new Array(16))
                .fill('')
                .map((_, index) => {
                return { id: index };
            })
                .sort(() => (Math.random() - .5))
                .map(({ id }, index) => {
                const x = index % 4;
                const y = Math.floor(index / 4);
                return {
                    id,
                    x: 100 + x * 200,
                    y: 100 + y * 150
                };
            })
        },
        {
            id: 'room-2',
            name: '4x4 Rycerzowa',
            complete: false,
            image: '/img.jpg',
            width: 4,
            height: 4,
            pieces: (new Array(16))
                .fill('')
                .map((_, index) => {
                return { id: index };
            })
                .sort(() => (Math.random() - .5))
                .map(({ id }, index) => {
                const x = index % 4;
                const y = Math.floor(index / 4);
                return {
                    id,
                    x: 100 + x * 200,
                    y: 100 + y * 150
                };
            })
        }
    ];
}
reset();
const $messages = new rxjs_1.Subject();
const sendRoomUpdate = (roomId, pieceId, x, y) => {
    const room = gameState.rooms
        .find(room => room.id === roomId);
    if (room) {
        $messages.next(new gde_game_framework_1.Message(gde_game_framework_1.GAME_MESSAGE_TYPE.UPDATE, {
            receivers: ['main-lobby', roomId],
            type: 'piece-moved',
            payload: { roomId, pieceId, x, y }
        }));
    }
};
const server = {
    handleAction: ({ action, payload }) => {
        var _a;
        switch (action) {
            case 'piece-moved':
                const { roomId, pieceId, x, y } = payload;
                const piece = (_a = gameState.rooms
                    .find(room => room.id === roomId)) === null || _a === void 0 ? void 0 : _a.pieces.find(({ id }) => id === pieceId);
                if (piece) {
                    piece.x = x;
                    piece.y = y;
                    sendRoomUpdate(roomId, pieceId, x, y);
                }
                break;
        }
    },
    handleRequest: ({ dataType }) => {
        switch (dataType) {
            case 'game-state':
                return gameState;
            default:
                return null;
        }
    },
    $messages
};
exports.default = server;
