"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MESSAGE_SOURCE = exports.MESSAGE_NAMESPACE = exports.MESSAGE_STATUS = exports.GAME_MESSAGE_TYPE = exports.ROOM_MESSAGE_TYPE = exports.MESSAGE_TYPE = void 0;
const rxjs_1 = require("rxjs");
const uuid_1 = require("uuid");
var MESSAGE_TYPE;
(function (MESSAGE_TYPE) {
    MESSAGE_TYPE["WELCOME"] = "welcome";
    MESSAGE_TYPE["GET_CONFIG"] = "get_config";
    MESSAGE_TYPE["CONFIG"] = "config";
    MESSAGE_TYPE["GET_USER_DATA"] = "get-user-data";
    MESSAGE_TYPE["USER_DATA"] = "user_data";
    MESSAGE_TYPE["ERROR"] = "error";
})(MESSAGE_TYPE = exports.MESSAGE_TYPE || (exports.MESSAGE_TYPE = {}));
var ROOM_MESSAGE_TYPE;
(function (ROOM_MESSAGE_TYPE) {
    ROOM_MESSAGE_TYPE["ENTER"] = "room:enter";
    ROOM_MESSAGE_TYPE["UPDATE"] = "room:update";
})(ROOM_MESSAGE_TYPE = exports.ROOM_MESSAGE_TYPE || (exports.ROOM_MESSAGE_TYPE = {}));
var GAME_MESSAGE_TYPE;
(function (GAME_MESSAGE_TYPE) {
    GAME_MESSAGE_TYPE["ACTION"] = "game:action";
    GAME_MESSAGE_TYPE["UPDATE"] = "game:update";
    GAME_MESSAGE_TYPE["REQUEST"] = "game:request";
})(GAME_MESSAGE_TYPE = exports.GAME_MESSAGE_TYPE || (exports.GAME_MESSAGE_TYPE = {}));
var MESSAGE_STATUS;
(function (MESSAGE_STATUS) {
})(MESSAGE_STATUS = exports.MESSAGE_STATUS || (exports.MESSAGE_STATUS = {}));
const MESSAGE_NAMESPACE = 'mbogf';
exports.MESSAGE_NAMESPACE = MESSAGE_NAMESPACE;
var MESSAGE_SOURCE;
(function (MESSAGE_SOURCE) {
    /** Wiadomość stworzona lokalnie */
    MESSAGE_SOURCE[MESSAGE_SOURCE["CREATED"] = 0] = "CREATED";
    /** Wiadomość odebrana */
    MESSAGE_SOURCE[MESSAGE_SOURCE["RECEIVED"] = 1] = "RECEIVED";
})(MESSAGE_SOURCE = exports.MESSAGE_SOURCE || (exports.MESSAGE_SOURCE = {}));
class Message {
    constructor(type, data, config) {
        this.id = uuid_1.v4();
        this.$response = new rxjs_1.Subject();
        this.config = Object.assign({ type: MESSAGE_SOURCE.CREATED, requireResponse: false }, config);
        this._data = data;
        this._type = type;
    }
    get data() {
        return this._data;
    }
    get type() {
        return this._type;
    }
    static fromSocket(type, { id, payload, requireResponse }) {
        const msg = new Message(type, payload, {
            requireResponse
        });
        msg.id = id;
        return msg;
    }
    send(socket) {
        // console.log('Message.send', this.type, {
        //     id: this.id,
        //     payload: this.data,
        //     requireResponse: this.config.requireResponse
        // });
        const requireResponse = this.config.requireResponse;
        socket.emit(this.type, {
            id: this.id,
            payload: this.data,
            requireResponse: this.config.requireResponse
        });
        if (!requireResponse) {
            return Promise.resolve(null);
        }
        return new Promise((resolve) => {
            socket.once(`response:${this.id}`, (data) => {
                const msg = Message.fromSocket('response', data);
                this.$response.next(msg);
                resolve(msg);
            });
        });
    }
    sendResponse(socket, data) {
        const msg = new Message(`response:${this.id}`, data);
        msg.send(socket);
    }
    sendErrorResponse(socket, data = {}) {
        this.sendResponse(socket, {
            status: 'error'
        });
    }
}
exports.default = Message;
