import { useGameClient } from "../..";
import useRXJS from "../use-rxjs";

export default function useRoom(id: string) {

    const {client} = useGameClient();
    const users = useRXJS(client.getRoomUsers(id));

    return {
        users: users || []
    }
}
