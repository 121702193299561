import { MBGameClient } from 'gde-game-framework';
import React, { useContext, useEffect, useRef } from 'react';
import { JagsawGame } from 'jigsaw-engine';

export default function createClient(url: string) {
    const engine = JagsawGame.client;


    const client = new MBGameClient({
        gameServerUrl: url,
        gameEngine: engine,
    });


    const context = React.createContext<{
        client: MBGameClient,
        engine: typeof JagsawGame.client
    }>({ client, engine });

    const GameClientProvider: React.FunctionComponent<{ authToken: string }> = ({ children, authToken }) => {

        const gameClient = useRef(client);
        const gameEngine = useRef(engine);

        useEffect(() => {
            client.connect(authToken);
        }, [authToken])

        return (
            <context.Provider value={{client: gameClient.current, engine: gameEngine.current}}>
                {children}
            </context.Provider>
        )
    }
    const useGameClient = () => {
        return useContext(context);
    }

    return {
        GameClientProvider,
        useGameClient
    }
}
