"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const message_1 = __importStar(require("../../../models/message"));
class GameServerModule {
    constructor(namespace, userManager, gameEngine, connectionsManager) {
        this.namespace = namespace;
        this.userManager = userManager;
        this.gameEngine = gameEngine;
        this.connectionsManager = connectionsManager;
        this.connectToSocket();
    }
    connectToSocket() {
        this.namespace.on('connection', (socket) => {
            const auth = socket.handshake.auth;
            //@ts-ignore
            const { authToken } = auth;
            const user = this.userManager.getUser(authToken);
            if (!user) {
                socket.disconnect();
                return;
            }
            this.connectionsManager.addSocket(user, 'game', socket);
            socket.on('disconnect', () => {
                this.connectionsManager.removeSocket(user, 'game', socket);
            });
            socket.on(message_1.GAME_MESSAGE_TYPE.ACTION, (data) => {
                const msg = message_1.default.fromSocket(message_1.GAME_MESSAGE_TYPE.ACTION, data);
                const action = msg.data;
                action.user = user;
                this.gameEngine.handleAction(action);
            });
            socket.on(message_1.GAME_MESSAGE_TYPE.REQUEST, (data) => {
                const msg = message_1.default.fromSocket(message_1.GAME_MESSAGE_TYPE.ACTION, data);
                const response = this.gameEngine.handleRequest(data.payload);
                msg.sendResponse(socket, response);
            });
        });
        this.gameEngine.$messages.subscribe((msg) => {
            if (msg.data.receivers.length === 0) {
                msg.send(this.namespace);
            }
            else {
                const sockets = this.connectionsManager.getSockets('game', msg.data.receivers);
                sockets.forEach((socket) => {
                    msg.send(socket);
                });
            }
        });
    }
}
exports.default = GameServerModule;
