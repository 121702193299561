"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ConnectionsManager {
    constructor() {
        this.connections = new Map;
    }
    getConnections(user) {
        const existed = this.connections.get(user);
        if (existed) {
            return existed;
        }
        const newConnections = {
            main: new Set(),
            game: new Set(),
            rooms: new Set()
        };
        this.connections.set(user, newConnections);
        return newConnections;
    }
    addSocket(user, type, socket) {
        const connections = this.getConnections(user);
        if (connections) {
            connections[type].add(socket);
        }
        else {
            console.error('ConnectionsManager:addSocket - Unknow user!');
        }
    }
    removeSocket(user, type, socket) {
        const connections = this.connections.get(user);
        if (connections) {
            connections[type].delete(socket);
        }
    }
    getSockets(type, permisions) {
        return Array.from(this.connections)
            .filter(([user]) => {
            return user.permisions.find(p => permisions.includes(p));
        })
            .map(([user, connections]) => {
            return [...connections[type]];
        })
            .reduce((prev, next) => {
            return [
                ...prev,
                ...next
            ];
        }, []);
    }
}
exports.default = ConnectionsManager;
