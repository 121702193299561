import { useCallback } from "react";
import { useGameClient } from "../.."

export function useRooms() {
    const {client} = useGameClient();

    const enter = useCallback((roomId: string) => {
        client.enterRoom(roomId);
    }, [client])

    return {
        enter
    }
}
