import React from "react";
import UsersInRoom from "../../components/users-in-room";
import useActiveRoomId from "../../modules/game/hooks/rooms/use-active-room-id"
import RoomRouter from "./room-router";

export default function GamePage() {

    const roomId = useActiveRoomId();

    return <div>
        <h1>Gra</h1>
        {roomId && <UsersInRoom id={roomId} />}
        {roomId && <RoomRouter id={roomId} />}
    </div>
}
