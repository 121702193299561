import useRoom from "../../modules/game/hooks/rooms/use-room";

type Props = {
    id: string
}

const UsersInRoom: React.FC<Props> = ({id}) => {

    const {users} = useRoom(id);

    return <div>
        <h3>Users in Room</h3>
        <ol>
            {users.map(u => {
                return <li key={u.id}>{u.data?.name}</li>
            })}
        </ol>
    </div>
}

export default UsersInRoom;
