import React from "react";
import styled from "styled-components";
import Jigsaw from "../../../../components/jagsaw";
import { useRooms } from "../../../../modules/game/hooks/rooms/use-rooms";

const AppContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const JigsawContainer = styled.div`
    height: 600px;
`;

export default function GameRoomPage() {

    const {enter} = useRooms();

    return (
        <>
            <h2>Room 1</h2>
            <button onClick={() => enter('main-lobby')}>Powrót do lobby</button>
            <AppContainer>
                <JigsawContainer>
                    <Jigsaw gameId={'room-1'} />
                </JigsawContainer>
            </AppContainer>
        </>
    )
}
