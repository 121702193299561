import React from "react"
import Game1Room from "./rooms/game-1"
import MainLobbyRoom from "./rooms/main-lobby"

type Props = {
    id: string
}

const RoomRouter: React.FC<Props> = ({id}) => {
    switch(id) {
        case 'main-lobby':
            return <MainLobbyRoom />

        case 'room-1':
            return <Game1Room />

        default:
            return <p>Unknown room</p>
    }
}

export default RoomRouter;
