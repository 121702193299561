import { useEffect } from "react";
import { useGameClient } from "../..";

export default function useOnPieceMovedMessage(roomId: string, callback: (id: number, x: number, y: number) => void) {
    const {engine} = useGameClient();

    useEffect(() => {
        const sub = engine
            .getPieceMoved(roomId)
            .subscribe(({id, x, y}) => {
                callback(id, x , y);
            });

        return () => {
            sub.unsubscribe();
        }
    }, [roomId, callback, engine]);
}
