import React, { useRef, useEffect, useState, useCallback } from "react";
import useOnPieceMovedMessage from "../../modules/game/hooks/game/use-on-piece-moved-message";
import useGetRoomState from "../../modules/game/hooks/game/use-room-state";
import useSendPieceMoveGameMessage from "../../modules/game/hooks/game/use-send-piece-move-game-message";
import createJigsaw from "./create";

type Props = {
    gameId: string
}

export type API = {
    movePiece: (id: number, x: number, y: number) => void
}

export type GameState = {
    width: number,
    height: number,
    image: string,
    pieces: { id: number, x: number, y: number }[]
}

const Jigsaw: React.FunctionComponent<Props> = (({ gameId }) => {
    const jsContainer = useRef<HTMLDivElement>(null);
    const getGameState = useGetRoomState(gameId);

    const [gameAPI, setGameAPI] = useState<API>();
    const sendPieceMoveGameMessage = useSendPieceMoveGameMessage();

    const onPieceMoved = useCallback((id: number, x: number, y: number) => {
        sendPieceMoveGameMessage({ game: gameId, id, x, y });
    }, [sendPieceMoveGameMessage, gameId])

    const onPieceMovedMessage = useCallback((pieceId, x, y) => {
        if (gameAPI) {
            gameAPI.movePiece(pieceId, x, y);
        }
    }, [gameAPI])

    useOnPieceMovedMessage(gameId, onPieceMovedMessage);

    useEffect(() => {
        (async () => {
            const gameState = await getGameState()
            if (jsContainer.current && gameState) {
                const params = {
                    container: jsContainer.current,
                    onPieceMoved,
                    gameState
                };

                const { movePiece } = createJigsaw(params);

                setGameAPI({
                    movePiece
                });
            }
        })();
    }, [jsContainer, getGameState, onPieceMoved])

    return <div ref={jsContainer} />
})

export default Jigsaw;
