"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const rxjs_1 = require("rxjs");
class RoomModel {
    constructor(config) {
        this.config = config;
        this.$users = new rxjs_1.BehaviorSubject([]);
    }
    get id() {
        return this.config.id;
    }
    get name() {
        return this.config.name;
    }
    get require() {
        return this.config.require;
    }
    get permisions() {
        return this.config.permisions;
    }
    addUser(user) {
        this.$users.next([...(new Set([
                ...this.$users.value,
                user
            ]))]);
        user.exitRoom();
        user.enterRoom(this);
    }
    removeUser(user) {
        this.$users.next(this.$users.value.filter(u => u !== user));
    }
    setUsers(users) {
        this.$users.next(users);
    }
}
exports.default = RoomModel;
