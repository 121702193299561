import { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useLogin from "../../modules/main/hooks/use-login";

type Props = {}

const Container = styled.div`

`;

const LoginForm: React.FC<Props> = () => {

    const {login} = useLogin()

    const {register, handleSubmit} = useForm();

    const onSubmit = useCallback((data: any) => {
        login({login: data.name});
    }, [login])

    return <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
            <input type="text" name="name" id="" ref={register}/>
            <button type="submit">ZALOGUJ</button>
        </form>
    </Container>
}

export default LoginForm;
